import styled, { css } from 'styled-components';

export const HeaderWrapperBlock = styled.div<{ $isSearchPage: boolean }>`
  ${({ $isSearchPage }) =>
    $isSearchPage &&
    css`
      background-color: ${({ theme }) => theme.colors.background.primary};
      height: 60px;

      > header {
        display: none;
      }

      @media screen and (min-width: ${({ theme }) => theme.tabletBreakpoint}) {
        > header {
          display: flex;
        }
        height: max-content;
      }
    `}
`;
