import { useRouter } from 'next/router';
import CONFIG from '../config';

export const useShowFlexibleComponents = () => {
  const router = useRouter();
  const isShowFlexibleComponents = CONFIG.adaptivePageRoutes.includes(
    router.route.split('#')[0]
  );

  return { isShowFlexibleComponents };
};
