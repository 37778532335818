type BooleanType = 'true' | 'false';

export interface AdriverCustom {
  1: 'Kiraye' | 'Alis';
  5: 1 | 2 | 3 | 4 | 5;
  6: string;
  7: BooleanType;
  8: string;
  9: number;
  10: BooleanType;
  11: BooleanType;
}

export interface Adriver {
  loadCompleteQueue: { flag: boolean };
  prm: { custom: AdriverCustom };
  reqPrm: { custom: string };
  custom: AdriverCustom;
  reload: () => unknown;
}

export enum TargetedBannerIds {
  bg = 'js-lotriver-bg-banner',
  catfish = 'catfish'
}

declare global {
  interface Window {
    adriver: (id: TargetedBannerIds, options: object) => Adriver;
  }
}
