import React from 'react';
import { useTranslation } from 'react-i18next';
import Image from 'next/image';
import {
  ButtonsGroupContainer,
  InitialFlexedHeader,
  MenuItem,
  MenuItemsContainer,
  Wrapper,
  ButtonPlaceholder,
  InitialLogo
} from './styled';
import Plus from '../../public/static/images/green-plus.svg';
import LinkWithIcon from '../LinkWithIcon';
import { SEARCH_RESULT_ROUTES } from '../../utils/helpers/constants';

export const InitialFlexibleHeader = () => {
  const { t } = useTranslation();

  return (
    <InitialFlexedHeader data-cy="header" id="header">
      <Wrapper>
        <ButtonPlaceholder className="placeholder-row placeholder-st__bottom-signin animation" />
        <InitialLogo href="/">
          <Image
            data-cy="header-menu-logo"
            data-stat="header-menu-logo"
            src="/static/images/brown-logo.svg"
            alt="logo"
            width={85}
            height={20}
          />
        </InitialLogo>
        <MenuItemsContainer>
          <MenuItem href={SEARCH_RESULT_ROUTES.SALE} className="link">
            {t('main_menu.sale')}
          </MenuItem>
          <MenuItem href={SEARCH_RESULT_ROUTES.RENT} className="link">
            {t('main_menu.lease')}
          </MenuItem>
          <MenuItem href={SEARCH_RESULT_ROUTES.RENT_DAILY} className="link">
            {t('search.form.paid_daily')}
          </MenuItem>
        </MenuItemsContainer>
        <ButtonsGroupContainer>
          <LinkWithIcon
            cypress="add-new-icon-btn"
            href="/items/new"
            stat="header-btn"
            icon={Plus}
            width={30}
            height={30}
            indent={false}
          />
        </ButtonsGroupContainer>
      </Wrapper>
    </InitialFlexedHeader>
  );
};
