import React from 'react';
import Link from 'next/link';
import PropTypes from 'prop-types';
import useScreenSize from '../../hooks/useScreenSize';
import { useClient } from '../../hooks/useClient';

export const CustomLink = ({
  cypress,
  stat,
  href,
  styledLink: StyledLink,
  children,
  onClick,
  ...rest
}) => {
  const { isSmall } = useScreenSize();
  const isClient = useClient();
  const Component = isSmall && isClient ? Link : 'a';
  const onClickHandler = isSmall && isClient ? onClick : null;

  if (!StyledLink) {
    return (
      <Component
        href={href}
        data-cy={cypress}
        data-stat={stat}
        onClick={onClickHandler}
        {...rest}
      >
        {children}
      </Component>
    );
  }

  return (
    <StyledLink href={href} data-cy={cypress} component={Component}>
      {children}
    </StyledLink>
  );
};

CustomLink.propTypes = {
  cypress: PropTypes.string,
  stat: PropTypes.string,
  href: PropTypes.string,
  onClick: PropTypes.func,
  styledLink: PropTypes.elementType,
  children: PropTypes.node
};
