import styled from 'styled-components';
import { FlexColumn } from '../../../styled/common';

export const StyledLayout = styled(FlexColumn)`
  min-height: 100vh;
  background-color: ${({ theme: { colors } }) => colors.background.primary};
  ${({ theme, $isScroll }) =>
    $isScroll &&
    theme.isWebView &&
    `border-bottom: 0.5px solid ${theme.colors.border.primary}`};
  padding-bottom: ${({ theme }) =>
    theme.isWebView
      ? `${theme.webview.insets.bottom + theme.mobile.tabBar.height}px`
      : 0};

  @media screen and (orientation: landscape) {
    min-height: 320px;
  }
`;
