/* eslint-disable new-cap */
/* eslint-disable no-new */
import { useSelector } from 'react-redux';
import { useEffect, useMemo, useRef } from 'react';
import { useRouter } from 'next/router';
import {
  selectAdriverSelectedCity,
  selectSearchFormCurrentState
} from '../../store/selectors';
import {
  ADRIVER_CATEGORIES_NAME,
  BUILDING_TYPE_ID,
  Cities
} from '../../utils/helpers/constants';
import useScreenSize from '../useScreenSize';
import { Adriver, AdriverCustom, TargetedBannerIds } from './types';

const lotriverBGLeftClass = '.lotriver-bg-left';
const lotriverBGRightClass = '.lotriver-bg-right';

const formatCustomData = (custom: object): string => {
  return Object.entries(custom)
    .map(([key, value]) => `${key}=${value}`)
    .join(';');
};

export const useTargetedBanner = (bannerId: TargetedBannerIds) => {
  const { isLarge } = useScreenSize();
  const banner = useRef<null | Adriver>(null);
  const router = useRouter();

  const storedFormState = useSelector(selectSearchFormCurrentState);
  const cityName: string = useSelector(selectAdriverSelectedCity);
  const categoryId = storedFormState?.category_id || BUILDING_TYPE_ID.FLAT_NEW;
  const cityId = storedFormState?.city_id;

  const custom = useMemo(
    (): AdriverCustom =>
      ({
        1: storedFormState?.leased === 'true' ? 'Kiraye' : 'Alis',
        5: storedFormState?.room_ids?.length
          ? parseInt(storedFormState.room_ids[0], 10)
          : NaN,
        6: ADRIVER_CATEGORIES_NAME[categoryId] || ADRIVER_CATEGORIES_NAME[2],
        7: storedFormState?.has_repair || 'false',
        8: cityName || Cities.Baku.az,
        9: storedFormState?.price_from || storedFormState?.price_to || 0,
        10: storedFormState?.has_bill_of_sale ? 'true' : 'false',
        11: storedFormState?.has_mortgage ? 'true' : 'false'
      }) as AdriverCustom,
    [storedFormState, cityName, categoryId, cityId]
  );

  const bannerData = {
    [TargetedBannerIds.bg]: { sid: 220686, bt: 52, bn: 4, custom },
    [TargetedBannerIds.catfish]: {
      sid: 220686,
      bn: 5,
      bt: 52,
      w: 700,
      h: 120,
      custom
    }
  };

  useEffect(() => {
    if (!Object.keys(custom).length) return;

    setTimeout(() => {
      try {
        banner.current = new window.adriver(bannerId, bannerData[bannerId]);
      } catch (e) {
        console.error(e);
      }
    }, 2000);
  }, []);

  useEffect(() => {
    const bannerElement = document.getElementById(bannerId);
    if (
      banner.current &&
      banner.current?.loadCompleteQueue?.flag &&
      bannerElement
    ) {
      if (bannerId === TargetedBannerIds.bg) {
        document.querySelector(lotriverBGLeftClass)?.remove();
        document.querySelector(lotriverBGRightClass)?.remove();
      }
      bannerElement.innerHTML = '';

      if (banner.current?.prm) {
        banner.current.prm.custom = custom;
      }
      if (banner.current?.reqPrm) {
        banner.current.reqPrm.custom = formatCustomData(custom);
      }
      banner.current?.reload();
    }
  }, [router]);

  useEffect(() => {
    const leftBanner = document.querySelector(
      lotriverBGLeftClass
    ) as HTMLElement | null;
    const rightBanner = document.querySelector(
      lotriverBGRightClass
    ) as HTMLElement | null;

    if (leftBanner && rightBanner) {
      leftBanner.style.display = isLarge ? 'block' : 'none';
      rightBanner.style.display = isLarge ? 'block' : 'none';
    }
  }, [isLarge]);

  return null;
};
