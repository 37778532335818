import styled, { css } from 'styled-components';
import { FlexCenterAligned, FlexibleDiv } from '../../styled/common';
import { FontWeight } from '../../styled/constants';
import { FlexibleHeaderProps } from '.';
import { CustomLink } from '../CustomLink';

export const FlexedHeader = styled(FlexCenterAligned).attrs({ as: 'header' })<
  Pick<FlexibleHeaderProps, 'isFixedHeader'>
>`
  background-color: ${({ theme }) => theme.colors.background.primary};
  ${({ isFixedHeader }) =>
    isFixedHeader &&
    css`
      position: sticky;
      width: 100%;
      height: 60px;
      top: 0;
      left: 0;
      z-index: 95;

      ~ .placeholder-st {
        margin-top: 25px;
      }
    `};
`;

export const InitialFlexedHeader = styled(FlexedHeader)`
  height: 74px;

  @media screen and (max-width: ${({ theme }) => theme.tabletBreakpoint}) {
    height: 60px;
  }

  .link {
    @media screen and (max-width: ${({ theme }) => theme.tabletBreakpoint}) {
      display: none;
    }
  }
`;

export const ButtonPlaceholder = styled.div`
  height: 15px;
  width: 18px;
  margin-right: 15px;

  @media screen and (max-width: ${({ theme }) => theme.tabletBreakpoint}) {
    display: none;
  }
`;

export const InitialLogo = styled(CustomLink)`
  margin-right: 25px;
`;

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.background.primary};
  position: sticky;
  z-index: 10;
  top: 0;
  align-items: center;
  padding: 15px;

  @media screen and (min-width: ${({ theme }) =>
      theme.tabletBreakpoint}) and (max-width: ${({ theme }) =>
      theme.desktopBreakpoint}) {
    padding: 15px 40px;
  }

  @media screen and (min-width: ${({ theme }) => theme.desktopBreakpoint}) {
    margin: 0 80px;
    padding: 15px 0;
    max-width: ${({ theme }) => theme.desktopBreakpoint};
  }
`;

export const ButtonsGroupContainer = styled(FlexibleDiv)`
  align-items: center;
  gap: 14px;
  margin-left: auto;
`;

export const CreateItemIconLink = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  box-sizing: border-box;
  text-align: center;
  font-size: 15px;
  font-weight: 500;
  padding: 10px 15px;
  white-space: nowrap;
  text-decoration: none;
  color: #fff;
  background-color: #3db460;
  width: 100%;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  height: 44px;
  border-radius: 14px;
  cursor: pointer;
`;

export const BookmarkIconLink = styled.a`
  display: block;
  background-image: url('/static/images/favorite-transparent.svg');
  width: 30px;
  height: 30px;
  font-size: 0;
  background-repeat: no-repeat;
  background-size: 20px 17px;
  background-position: center;
`;

export const MenuItemsContainer = styled(FlexibleDiv)`
  gap: 10px;
`;

const shouldForwardProp = (prop: string) => !['isCurrentPage'].includes(prop);
export const MenuItem = styled(CustomLink).withConfig({
  shouldForwardProp
})<{ isCurrentPage: boolean }>`
  padding: 10px 5px;
  font-size: 15px;
  color: #2f1f19;
  text-decoration: none;
  transition: color 0.2s ease-in-out;
  letter-spacing: 0.05px;

  ${({ isCurrentPage, theme }) =>
    isCurrentPage &&
    css`
      color: ${theme.colors.systemColors.brand};
      font-weight: ${FontWeight.medium};
    `}

  &:hover {
    color: ${({ theme }) => theme.colors.systemColors.brand};
  }
`;

export const WebViewButton = styled.button`
  position: absolute;
  right: 0;
  width: 45px;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.background.primary};
  padding: 0;

  i {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  svg {
    display: block;
    flex: 1;
  }
`;
